import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import '../css/projectCard.css';


function buttons(type, props) {
    if (props.details.frontmatter.type === 1) { 
        return <CardActions style={{ justifyContent: 'center' }}>
            <Button size="small" color="primary" href={props.details.frontmatter.url} target='newtab'>
                Read More
                </Button>
        </CardActions>
    } else if (props.details.frontmatter.type === 2) {
        return (<CardActions style={{ justifyContent: 'space-around' }}>
            <Button size="small" color="primary" href={props.details.frontmatter.code} target='newtab'>
                Code
        </Button>
            <Button size="small" color="primary" href={props.details.frontmatter.url} target='newtab'>
                Demo
        </Button>
        </CardActions>)
    } else {
        return <CardActions style={{ justifyContent: 'center' }}>
            <Button size="small" color="primary" href={props.details.frontmatter.url} target="newtab">
                Read More
                </Button>
        </CardActions>
    }
}


export default function BlogCard(props) {
    if (props.details.frontmatter.excerpt != null && props.details.frontmatter.excerpt.length >= 90) {
        props.details.frontmatter.excerpt = props.details.frontmatter.excerpt.substring(0, 90) + '...'
    }

    return (
        <Card className='pcard' variant='outlined'>
            <CardActionArea href={props.details.frontmatter.url} target='newtab'>
                <CardContent style={{ height: '150px' }} >
                    <Typography gutterBottom variant="h6" component="h4">
                        {props.details.frontmatter.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <span dangerouslySetInnerHTML={{ __html: props.details.frontmatter.excerpt }} />
                    </Typography>
                </CardContent>
            </CardActionArea>
            {buttons(props.details.frontmatter.type, props)}
        </Card>
    );
}

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BlogCard from './card';
import { StaticQuery, graphql } from "gatsby";


const subCategories = [
    {
        'mdx': 'codecell',
        'title': 'KJSCE CodeCell',
        'tagline': ''
    },
    {
        'mdx': 'probably',
        'title': 'Team Probably',
        'tagline': ''
    }
]


function getBlogs(data, subCategory) {
    let p = []
    const { edges: blogs } = data.allMdx;
    for (let i = 0; i < blogs.length; i++) {
        const ele = blogs[i].node;
        if (ele.frontmatter.category === 'blogs' &&
            ele.frontmatter.subcategory === subCategory.mdx &&
            ele.frontmatter.type !== 0 &&
            ele.frontmatter.public) { 
            p.push(
                <Grid item xs={12} sm={6} md={6}>
                    <BlogCard details={ele} />
                </Grid>
            )
        }

    }
    return p;
}

function createCategories(data) {
    const { edges: x } = data.allMdx;
    let categories = []
    for (let i = 0; i < subCategories.length; i++) {
        const subCategory = subCategories[i];
        categories.push(
            <Grid container item xs={12} spacing={2}>
                <Grid item >
                    <Typography variant="h5" component="h4">
                        {subCategory.title}
                    </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography variant="body1" component="h6" className='indent-quote'>
                        <div dangerouslySetInnerHTML={{ __html: subCategory.tagline  }} />
                    </Typography>
                </Grid> */}
                <Grid container item xs={12} justify='space-around'>
                    {getBlogs(data, subCategory)}
                </Grid>
            </Grid>
        )
    }
    return categories
}

export default function AllBlogs(props) {
    return (
        <StaticQuery
            query={graphql`
            query {
                allMdx(filter: {frontmatter: {category: {in: ["blogs"]}}}, sort: {fields: frontmatter___index, order: ASC}) {
                    edges {
                        node {
                            id
                            frontmatter {
                            category
                            subcategory
                            title
                            comment
                            image
                            code
                            url
                            date(formatString: "MMMM DD, YYYY")
                            excerpt
                            public
                            mainpage
                            type
                            }
                        }
                    }
                }
            }
        `}

            render={data => (
                <Grid container spacing={5} justify='space-around'>
                    {createCategories(data)}
                </Grid>
            )}
        />
    );
}
import React from "react"
import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AllBlogs from '../components/blogs_page/blogs';
import Layout from "../components/layout"
import SEO from "../components/seo"


export default function Projects() {
    return (
        <Layout page="blogs">
            <SEO title="Blogs" />
            <div className='full_background'>
                <Container maxWidth="xl" className='data_header'>
                    <Container maxWidth="md" style={{
                        paddingTop: '8rem',
                        paddingBottom: '4rem', textAlign: 'center'
                    }}>
                        <Typography variant="h3" component="h3">
                            <b>
                                Blogs
                        </b>
                        </Typography>
                        <br></br>
                        <Typography variant="body1" component="h5">
                            Currently none of these are solely written by me, but I have some contribution to all of them.
                            Will be writing some myself as soon as I am motivated enough.
                    </Typography>
                    </Container>
                </Container>
                <Container maxWidth="md" style={{
                    paddingTop: '3rem',
                    paddingBottom: '4rem'
                }}>
                    {/* <div className='indent-quote'>
                        <Typography variant="body1" component="h5">
                            Currently none of these are solely written by me, but I have some contribution to all of them.
                            Will be writing some myself as soon as I am motivated enough.
                        </Typography>
                    </div> */}
                    <AllBlogs />
                    <div className='indent-quote' style={{ marginTop: '2rem' }}>
                        <Typography variant="body1" component="h5">
                            Also while you are in my realm, have a look at all the <Link className='textLinks' to='/projects'>projects</Link> (some have dramatic/enlightening stories which you may fancy reading) and also check out other <Link className='textLinks' to='/stuff'>stuff</Link> which I wanted to share but the dumb AI failed to classify them into projects/blogs.
                        </Typography>
                    </div>
                </Container>
            </div>
        </Layout>
    )
}
